import React from "react"
import "../sass/_404.scss"
import "../components/layout.scss"
import { SubpageLayout } from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <SubpageLayout>
    <SEO title="404: Not found" />
    <div className="container h-200">
            <div className="row h-100 align-items-left justify-content-left text-left">
    <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-end" style={{marginTop: "100px"}}>
      <h1 className="text-uppercase text-white font-weight-bold">
      Sorry, we could not find what you are looking for.
      </h1>
    </div></div>
    </div>
    <div className="col-lg-7 col-md-7 col-sm-8 col-8 align-self-baseline">
      <p className="text-white-75 font-weight-light mb-5">
      <div align="center" style={{maxWidth: '350px', margin: 'auto'}}>
    
    <svg version="1.1" baseProfile="basic" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 612 792">      
    <path class="st0" d="M203.29,88.86c-3.3,0.02-6.57,0.05-9.82,0.1c-74.11,1.19-136.37,11.45-182.62,22.02"/>
    <path class="st0" d="M516.54,133.72c-45.06-14.44-107.99-30.59-184.61-38.19"/>
    <path class="st1" d="M209.28,102.34c-0.35-0.36-21.64-26.02-7.52-57.91c0,0,5.13-21.26,25.64-17.23c0,0,12.65,5.86,24.27,12.1
      c11.62,6.23,30.77-2.57,30.77-2.57s8.21-8.31,24.27-10.2s27.01,3.97,32.14,13.14s-3.42,43.25-3.42,43.25s-10.26,35.92-14.02,55.35
      s-4.79,45.82-4.79,45.82s-1.37,15.03-16.07,11.36c-14.7-3.67-22.22-65.98-22.22-65.98s-2.34-23.46-12.45-0.37
      c-10.11,23.09-7.72,52.41-7.72,52.41l1.37,14.5c0,0-4.79,14.5-20.17,0c-15.39-14.5-13.33-40.53-13.33-40.53
      S223.64,117.01,209.28,102.34z"/>
    <path class="st2" d="M363.21,97.39c-0.32-0.41-19.39-28.98,0.01-58.47c0,0,8.38-20.3,29.61-13.26c0,0,12.62,7.68,24.1,15.57
      s33.03,2.02,33.03,2.02s9.86-7.02,27.18-6.51c17.32,0.51,28.13,7.93,32.31,17.77s-9.6,42.33-9.6,42.33s-15.85,34.06-22.53,52.74
      c-6.68,18.68-11.41,44.67-11.41,44.67s-3.53,14.68-18.63,8.87c-15.1-5.81-14.48-68.63-14.48-68.63s0.76-23.58-13.17-2.21
      c-13.93,21.37-15.44,50.77-15.44,50.77l-0.55,14.57c0,0-7.09,13.66-21.42-2.99s-8.56-42.11-8.56-42.11S376.43,114.04,363.21,97.39z"
      />
    <path class="st2" d="M53.75,104.46c-0.35-0.36-21.64-26.02-7.52-57.91c0,0,5.13-21.26,25.64-17.23c0,0,12.65,5.86,24.27,12.1
      s30.77-2.57,30.77-2.57s8.21-8.31,24.27-10.2s27.01,3.97,32.14,13.14s-3.42,43.25-3.42,43.25s-10.26,35.92-14.02,55.35
      s-4.79,45.82-4.79,45.82s-1.37,15.03-16.07,11.36c-14.7-3.67-22.22-65.98-22.22-65.98s-2.34-23.46-12.45-0.37
      s-7.72,52.41-7.72,52.41l1.37,14.5c0,0-4.79,14.5-20.17,0c-15.39-14.5-13.33-40.53-13.33-40.53S68.11,119.13,53.75,104.46z"/>
    <path class="st3" d="M96.14,41.42c0,0,1.48,15.54,33.29,16.38"/>
    <path class="st3" d="M449.96,43.24c0,0-19.29,20.96-41.06,13.43"/>
    <path class="st3" d="M363.21,97.39c0,0,21.41,17.06,35.65,16.93"/>
    <path class="st0" d="M331.93,95.53"/>
    </svg>
  </div>
      </p>
      
      
    </div>
    
    
  </SubpageLayout>
)

export default NotFoundPage
